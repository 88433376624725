import styled from '@emotion/styled'
import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useGlobalStore } from '../../global-store'
import { getEventDetail } from '../api/events'
import { colors, constants } from '../tokens'
import { getPricingSorted } from '../utils'

export default function PricingView({ eventId }: { eventId: string }) {
  const { userInfo } = useGlobalStore()
  const [eventData, setEventData] = useState({
    isLoading: false,
    error: false,
    data: null,
  })

  useEffect(() => {
    if (!userInfo.isLoading && userInfo.data && eventId) {
      setEventData({ data: null, isLoading: true, error: false })
      getEventDetail({
        eventId,
        employeeId: userInfo?.data?.EmployeeDataModel?.EmployeeId,
      }).then((data) => {
        setEventData({ isLoading: false, error: false, data })
      })
    }
    if (!eventId) {
      setEventData({ isLoading: false, error: false, data: null })
    }
  }, [userInfo.isLoading, userInfo.data, eventId])

  return eventData.data ? (
    <TableWrapper size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="caption">SEATING LOCATION</Typography>
          </TableCell>
          <TableCell sx={{ textAlign: 'right' }}>
            <Typography variant="caption">PRICING</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {getPricingSorted(eventData.data)
          .filter((item: any) => item.IsSoldOut !== 0)
          .map((item: any) => (
            <TableRowWrapper key={item.id}>
              <TableCell>{item.location}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>{item.price}</TableCell>
            </TableRowWrapper>
          ))}
      </TableBody>
    </TableWrapper>
  ) : eventData.isLoading ? (
    <LoadingSection>
      <CircularProgress />
    </LoadingSection>
  ) : (
    <></>
  )
}

const Wrapper = styled(Stack)`
  width: 100%;
  color: #333;
  margin-top: 80px;
`

const LoadingSection = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  margin: ${constants.globalBufferMedium} 0;
`

const TableWrapper = styled(Table)`
  width: 100%;
  td,
  th {
    //  color: white !important;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
    border-color: ${colors.globalLightBlue};
  }
  tbody tr::nth-of-type(odd) {
    background-color: '#e1f2fb';
  }
`

const TableRowWrapper = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#e1f2fb',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
