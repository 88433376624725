import styled from '@emotion/styled'
import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  Stack,
  css,
} from '@mui/material'
import dayjs from 'dayjs'
import Head from 'next/head'
import Image from 'next/image'

import { Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useGlobalActions, useGlobalStore } from '../../../global-store'
import { getEvents } from '../../api/events'
import { EventDataType } from '../../common-types'
import BackToTop from '../../components/back-to-top'
import ButtonPrimary from '../../components/button-primary'
import ButtonSecondary from '../../components/button-secondary'
import Container from '../../components/container'
import DefaultResponse from '../../components/default-response'
import EventFilters from '../../components/event-filters'
import FullscreenLoader from '../../components/fullscreen-loader'
import LoadMoreSection from '../../components/load-more-section'
import PageHeader from '../../components/page-header'
import PricingView from '../../components/pricing-view'
import Section from '../../components/section'
import VerticalDrawer from '../../components/vertical-drawer'
import { PAGE_SIZE } from '../../config'
import { colors, constants, screenSize } from '../../tokens'

const venueImageMap = {
  KovZpZAEAd6A: '/v1576694694/eventengine/cfhxzvp1b6zhlri7d3wz.png', // beacon
  KovZpZAE7vdA: '/v1576694679/eventengine/ngnh5mcbscrhqhzlfreu.png', // radio city music hall
  // aaaa: '/v1576694642/eventengine/xrc6c5lbt2qra5llcuwt.png', // chicago theater
  KovZpZA7AAEA: '/v1576694663/eventengine/jvngimgmp7yahql6ytrq.png', // msg
  KovZpZA7kvlA: '/v1676487783/eventengine/mznbvtz5wxay6zikc76h.png', // theater @ msg
}

const getVenueLogo = (venueId: string): string | '' => {
  const urlPrefix = 'https://res.cloudinary.com/msg-suites-uat/image/upload'
  if (venueId && venueImageMap[venueId as keyof typeof venueImageMap]) {
    return `${urlPrefix}${venueImageMap[venueId as keyof typeof venueImageMap]}`
  }
  return ``
}

const EventDateHeader = ({
  index,
  item,
  currentEventsData,
}: {
  index: number
  item: any
  currentEventsData: any
}) => {
  if (index === 0) {
    return (
      <PageHeader
        title={`${dayjs(item.EventDateTime).format('MMMM YYYY')}`}
        style={{ paddingBottom: 0, paddingTop: 0 }}
      />
    )
  }
  if (
    dayjs(currentEventsData[index - 1].EventDateTime).get('month') !==
    dayjs(item.EventDateTime).get('month')
  ) {
    return <PageHeader title={`${dayjs(item.EventDateTime).format('MMMM YYYY')}`} />
  }
  return <></>
}

function EventName({ eventName = '' }) {
  const eventNameSeparator = eventName?.includes(' - ') ? ' - ' : ':'
  const eventNameArray = eventName.split(eventNameSeparator)
  const eventIsSports =
    eventName?.toLowerCase()?.includes('knicks') || eventName?.toLowerCase()?.includes('rangers')
  return (
    <ItemDetailHeader>
      {eventIsSports ? (
        <div>
          <strong>{eventName}</strong>
        </div>
      ) : (
        <>
          <div>
            <strong>{eventNameArray[0]}</strong>
          </div>
          <div style={{ fontSize: '0.8em', lineHeight: 2, opacity: 0.75 }}>
            {eventNameArray[1] ? ` ${eventNameArray[1]}` : ''}
          </div>
        </>
      )}
    </ItemDetailHeader>
  )
}

export default function Events() {
  // pagination control for loading events
  const [dataPagesLoaded, setDataPagesLoaded] = useState(1)
  const [dataPageLoadError, setDataPageLoadError] = useState(false)
  const [isLoadingMoreEvents, setIsLoadingMore] = useState(false)
  const [currentEventId, setCurrentEventId] = useState('')
  const { currentEvents, eventFilters, userInfo } = useGlobalStore()
  const { setCurrentEvents } = useGlobalActions()
  const router = useRouter()

  const onLoadMore = () => {
    setDataPagesLoaded((oldVal) => oldVal + 1)
  }

  useEffect(() => {
    if (dataPagesLoaded > 1) {
      setDataPageLoadError(false)
      if (!currentEvents.isLoading && currentEvents.data) {
        setIsLoadingMore(true)
        const filters = eventFilters.data
          ? {
              date: eventFilters.data.date || '',
              eventName: eventFilters.data.eventName || '',
              venueName: eventFilters.data.venueName || '',
            }
          : {}
        getEvents({ page: dataPagesLoaded, ...filters })
          .then((data) => {
            setIsLoadingMore(false)
            !!data &&
              setCurrentEvents({
                data: [...currentEvents.data, ...data?.EventsByRequest],
                totalCount: data.TotalCount,
              })
          })
          .catch(() => {
            setDataPageLoadError(true)
            setIsLoadingMore(false)
          })
      }
    }
  }, [dataPagesLoaded])

  useEffect(() => {
    if (eventFilters && eventFilters.data) {
      setDataPagesLoaded(1)
    }
  }, [eventFilters])

  return (
    <>
      <Head>
        <title>Madison Square Garden -- Request Tix -- Events List</title>
      </Head>
      <Container>
        <FullscreenLoader show={Boolean(currentEvents.isLoading) || Boolean(userInfo.isLoading)} />
        <EventFilters />
        <EventsSection>
          {!!currentEvents.data && !!currentEvents.data.length ? (
            <Stack spacing={2}>
              {currentEvents.data.map((item: EventDataType, index: number) => (
                <div key={`${item.EventId}-${index}`}>
                  <EventDateHeader index={index} currentEventsData={currentEvents.data} item={item} />
                  <EventWrapper>
                    <EventItem
                      sx={{
                        background: colors.globalDarkBlueTransparent,
                        color: 'white',
                      }}
                    >
                      <DateContainer mode="desktop" dateTime={item.EventDateTime} />
                      <CardMedia image={item['ImageUrl360X640']} title={item.EventName}>
                        {item.Status === 'cancelled' ? <CancelledOverlay /> : <></>}
                      </CardMedia>
                      <DateContainer mode="mobile" dateTime={item.EventDateTime} />
                      <CardContent>
                        <Stack spacing={2}>
                          {!!getVenueLogo(item.VenueId) && (
                            <VenueImageContainer>
                              <Image
                                src={getVenueLogo(item.VenueId)}
                                alt="Venue logo"
                                style={{ objectFit: 'scale-down', maxWidth: '125px' }}
                                sizes="125px"
                                fill
                              />
                            </VenueImageContainer>
                          )}
                          <EventName eventName={item.EventName} />
                          <Typography variant="button" fontWeight={600}>
                            {dayjs(item.EventDateTime).format('h:mm A')}
                          </Typography>
                        </Stack>
                      </CardContent>
                      <CardActions
                        sx={{
                          padding: `${constants.globalBufferSmall}`,
                          paddingTop: '4px',
                          minWidth: '280px',
                          marginLeft: 'auto',
                        }}
                      >
                        <ButtonSecondary
                          onClick={() => setCurrentEventId(item.EventId)}
                          variant="outlined"
                        >
                          PRICING
                        </ButtonSecondary>
                        <ButtonPrimary
                          disabled={item.Status === 'cancelled'}
                          onClick={() => router.push(`/tickets?eventid=${item.EventId}`)}
                          variant="contained"
                        >
                          Get TICKETS
                        </ButtonPrimary>
                      </CardActions>
                    </EventItem>
                    <VerticalDrawer
                      isOpen={currentEventId === item.EventId}
                      setIsClosed={() => setCurrentEventId('')}
                      anchor="right"
                      subtitle="Event Pricing for"
                      title={item.EventName}
                      footer={
                        <>
                          <ButtonSecondary
                            mode="light"
                            style={{ marginRight: `${constants.globalBufferSmall}` }}
                            onClick={() => setCurrentEventId('')}
                            fullWidth
                          >
                            Close
                          </ButtonSecondary>
                          <ButtonPrimary
                            disabled={item.Status === 'cancelled'}
                            onClick={() => router.push(`/tickets?eventid=${item.EventId}`)}
                            variant="contained"
                          >
                            Get TICKETS
                          </ButtonPrimary>
                        </>
                      }
                    >
                      <PricingView eventId={item.EventId} />
                    </VerticalDrawer>
                  </EventWrapper>
                </div>
              ))}
              {!!currentEvents.totalCount &&
                dataPagesLoaded * PAGE_SIZE < Number(currentEvents.totalCount) && (
                  <LoadMoreSection>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={onLoadMore}
                      size="large"
                      sx={{ height: '45px' }}
                      fullWidth
                    >
                      <div style={{ fontWeight: '500', color: 'black' }}>
                        {isLoadingMoreEvents ? (
                          <CircularProgress color="inherit" />
                        ) : (
                          'Show more results'
                        )}
                      </div>
                    </Button>
                  </LoadMoreSection>
                )}
              <BackToTop />
            </Stack>
          ) : (
            <></>
          )}
          {!!currentEvents.data && currentEvents.data.length === 0 && !currentEvents.isLoading ? (
            <Stack spacing={3}>
              <PageHeader title="Events" style={{ paddingBottom: 0, paddingTop: 0 }} />
              <Item>
                <ItemDetail>
                  <DefaultResponse>No results found</DefaultResponse>
                </ItemDetail>
              </Item>
            </Stack>
          ) : (
            <></>
          )}
        </EventsSection>
      </Container>
    </>
  )
}

const EventWrapper = styled.div`
  // background: ${colors.globalDarkBlueTransparent};
  color: white;
`

const EventsSection = styled(Section)`
  padding-top: 0;
  margin-top: ${constants.globalBufferXL};
  ${screenSize.large(css`
    padding-top: ${constants.globalBufferXL};
    margin-top: 56px;
  `)}
`

const DateContainer = ({ mode, dateTime }: { mode: 'mobile' | 'desktop'; dateTime: string }) => {
  const Wrapper = mode === 'mobile' ? DateContainerMobile : DateContainerDesktop
  return (
    <Wrapper>
      <DateText variant="button" fontWeight={600} fontSize="12px">
        <span>{dayjs(dateTime).format('ddd')}</span>
        <DateDayDisplay>{dayjs(dateTime).format('DD')}</DateDayDisplay>
        <span>{dayjs(dateTime).format("MMM 'YY")}</span>
      </DateText>
    </Wrapper>
  )
}

const EventItem = styled(MuiCard)`
  // border-top: 1px solid #2561a7;
  // border-bottom: 1px solid #061729;
  ${screenSize.large(css`
    display: flex;
    min-height: 180px;
    padding: ${constants.globalBufferSmall} 0;
  `)}
`

const DateContainerDesktop = styled(CardContent)`
  display: none;
  ${screenSize.large(css`
    display: flex;
    width: 90px;
    min-width: 90px;
    padding-top: 32px;
  `)}
`

const DateContainerMobile = styled(CardContent)`
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  ${screenSize.large(css`
    display: none;
  `)}
`

const DateText = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${colors.globalLighterBlue};
  flex-direction: row;
  ${screenSize.large(css`
    flex-direction: column;
    line-height: 1.2;
    width: 56px;
    color: ${colors.globalLighterBlue};
  `)}
`

const DateDayDisplay = styled.span`
  font-size: 24px;
  margin: 0 2.5px;
  padding: 0 2px;
  font-weight: 900;
  color: white;

  ${screenSize.large(css`
    margin: 0;
    font-size: 34px;
  `)}
`

const CardMedia = styled(MuiCardMedia)`
  height: 205px;
  width: auto;
  position: relative;

  ${screenSize.large(css`
    height: auto;
    width: 280px;
    min-width: 280px;
    border: 1px solid #376191;
  `)}
`

const CancelledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(75, 75, 75, 0.75);
  backdrop-filter: blur(2px);
  &::after {
    font-size: 48px;
    color: #ff3333;
    text-shadow: 0 0 8px black;
    letter-spacing: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    content: 'cancelled';
    transform: rotate(-15deg) translateY(60px) translateX(-20px);
  }
`

const VenueImageContainer = styled.div`
  // width: 100%;
  height: 20px;
  position: relative;
`

const ItemDetail = styled.div`
  padding: ${constants.globalBufferSmall};
`

const ItemDetailHeader = styled.div`
  display: block;
  margin: 12px 0 4px !important;
  font-size: 20px;
  line-height: 1.2;
  // color: ${colors.globalLightBlue};
  ${screenSize.large(css`
    font-size: 18px;
  `)}
`

interface ItemProps {
  key?: string
  children: JSX.Element | JSX.Element[]
}

const Item = styled((props: ItemProps) => <Section variant="secondary" {...props} />)`
  // padding-bottom: 1em;
  margin: 0;
  padding: 0;

  ${screenSize.large(css`
    display: flex;
    margin: 0 auto ${constants.globalBufferMedium} !important;
    min-width: ${constants.desktopContentWidth};
    padding: ${constants.globalBufferSmall};
  `)}
`
