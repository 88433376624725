import styled from '@emotion/styled'
import { ChevronLeft } from '@mui/icons-material'
import { Button as MuiButton, Typography, css } from '@mui/material'
import { useRouter } from 'next/router'
import { colors, constants, screenSize } from '../tokens'
import Section from './section'

interface PageProps {
  title?: string | React.ReactNode
  children?: React.ReactNode
  style?: {}
  color?: string
  backAction?: string
  OtherActions?: React.ReactNode
}

export default function PageHeader({ title, style, color, backAction, OtherActions }: PageProps) {
  const router = useRouter()
  return (
    <Section
      style={{
        padding: `${constants.globalBufferSmall}`,
        // paddingLeft: 0,
        paddingBottom: '4px',
        display: 'flex',
        // flexWrap: 'wrap',
        alignItems: 'center',
      }}
      otherStyles={{ ...style, color: color || '#fff' }}
    >
      {backAction ? (
        <Button
          // onClick={() => router.push(backAction)}
          onClick={() => router.back()}
        >
          <ChevronLeft
            style={{ color: colors.globalLightBlue, stroke: colors.globalLightBlue, strokeWidth: '2px' }}
          />
        </Button>
      ) : (
        <></>
      )}
      {title ? (
        <Typography
          variant="h4"
          sx={{
            lineHeight: '1.2',
            fontSize: '28px',
            fontWeight: '900',
            textShadow: '1px 1px 10px rgba(0, 0, 0, .8)',
          }}
        >
          {title}
        </Typography>
      ) : (
        <></>
      )}
      {OtherActions ? <OtherActionsContainer>{OtherActions}</OtherActionsContainer> : <></>}
    </Section>
  )
}

const OtherActionsContainer = styled.div`
  margin-left: auto;
`
const Button = styled(MuiButton)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  color: ${colors.globalLightBlue};
  border: 3px solid ${colors.globalLightBlue};
  opacity: 0.85;
  margin-right: ${constants.globalBufferMedium} !important;
  background-color: transparent;
  border-radius: 50%;
  margin: 0;
  // margin-top: 10px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${screenSize.large(css`
    margin-bottom: 4px;
  `)}
`
