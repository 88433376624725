import styled from '@emotion/styled'
import { KeyboardArrowUp } from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'

// scroll functionality shamelessly stolen from https://stackoverflow.com/questions/70659833/get-scroll-position-with-nextjs

const buttonVisibilityStyles = {
  show: { display: 'flex' },
  hide: { display: 'none ' },
}
export default function BackToTop() {
  const [scrollY, setScrollY] = useState(0)

  const onScroll = useCallback(() => {
    const { pageYOffset, scrollY } = window
    setScrollY(window.pageYOffset)
  }, [])

  useEffect(() => {
    //add eventlistener to window
    window.addEventListener('scroll', onScroll, { passive: true })
    // remove event on unmount to prevent a memory leak with the cleanup
    return () => {
      // @ts-ignore
      window.removeEventListener('scroll', onScroll, { passive: true })
    }
  }, [])

  return (
    <BackToTopButton
      onClick={() => window?.scroll(0, 0)}
      style={scrollY > 315 ? buttonVisibilityStyles.show : buttonVisibilityStyles.hide}
    >
      <KeyboardArrowUp sx={{ fontSize: '32px' }} />
    </BackToTopButton>
  )
}

const BackToTopButton = styled.button`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 32px;
  border: 0;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #307ec2;
  color: white;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.25);
  z-index: 1000;
`
